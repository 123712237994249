import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 p-10 w-full text-white">
      <div className="flex justify-center items-center h-20">
        {/* 왼쪽 KYPL 문구 */}
        <div className="text-6xl font-bold text-blue-500">KYPL</div>
      </div>

      {/* 가운데 이용문의 정보 */}
      <div className="text-center mt-4">
        <p>이용문의</p>
        <p>메일: Support@kypl.com</p>
        <p>전화: 123-456-7890</p>
        <p>제주플랜 여행 계획 플랫폼</p>
      </div>

      {/* 고객지원 정보 */}
      <div className="mt-4 text-center text-sm">
        <p>고객지원: 월~금 09:00 - 18:00</p>
      </div>
    </footer>
  );
};

export default Footer;